import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import * as XLSX from "xlsx";
import SystemModal from "../../Common/SystemModal";
import Loader from "../../Common/Loader";
import ajaxAccounting from "../../../util/remote/ajaxAccounting";
import jsPDF from "jspdf";

const InventoryJournaltExportPdf = (props) => {
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [PaymentVoucher, setPAymentVoucher] = useState([]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setToDate(today);
  }, []);

  // Function to handle footer rendering
  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={() => getPaymentVoucher(controls)}>
            Export
          </button>
        </>
      );
    }
  };

  // Function to get payment vouchers
  const getPaymentVoucher = async () => {
    if (!fromDate || !toDate) {
      toast.error("Please fill in both From Date and To Date.");
      return;
    }
    const data = {
      projectID: props.projectID,
      trans_type: props.trans_type, // Assuming trans_type and page are props
      page: props.page,
      fromDate,
      toDate,
    };
    setPAymentVoucher([]);
    setLoading(true);

    const server_response = await ajaxAccounting.getTransactionsForexport(data);
    setLoading(false);
    if (server_response.status === "OK") {
      setPAymentVoucher(server_response.details);
    } else {
      toast.error("No data available for export");
    }
  };
  useEffect(() => {
    if (PaymentVoucher.length > 0) {
      exportToPDF();
    }
  }, [PaymentVoucher]);
  // Function to export data to Excel

  const exportToPDF = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = `Reciept_transactions_ from ${fromDate} to ${toDate}`;
    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name
    pdf.setFontSize(14);
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50);

    // Add company address
    pdf.setFontSize(12);
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    );
    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    );

    // Add printing date
    pdf.setFontSize(9);
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130);

    // Add header description
    pdf.setFontSize(12);
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160);

    // Define columns for the table
    const columns = [
      "No.",
      "Voucher_reff",
      "Project",
      "ledger Debited",
      "Credited Ledger",
      "Amount",
      "Description",
      "Date",
      "Reciever",
      "Created By",
    ];

    // Check if PaymentVoucher is an array and map it to table format
    let data = [];

    if (Array.isArray(PaymentVoucher)) {
      data = PaymentVoucher.map((item, key) => [
        key + 1,
        item.folio_reff,
        item.projectID.project_name,
        item.ledgerID_debited.Ledger_name,
        item.ledgerID_credited.Ledger_name,
        item.amount.amount_p,
        item.description,
        item.date.short_date,
        item.reciever,
        item.created_by.name,
      ]);
    }

    // Add the table to the PDF
    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`Inventory_journal_transaction_from_${fromDate}_to ${toDate}.pdf`);
  };

  // Event handler for export button

  return (
    <SystemModal title="" id="model-new-ledger" size="lg" footer={RenderFooter}>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div
                className="card custom-card"
                style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  <div>
                    <h6 className="card-title mb-4">
                      Contra Transactions Exporting To PDF
                    </h6>
                  </div>

                  <form method="">
                    <div className="row">
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          From Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          To Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default InventoryJournaltExportPdf;
