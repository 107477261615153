import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async fetchProjectList(data) {
    let response = await apiCall("project/list", data);
    return response;
  },
  async getProjectsMinnimalData(data) {
    let response = await apiCall("project/list_all_minimal_data", data);
    return response;
  },
  async getProjectsMinnimalDataCliented(data) {
    let response = await apiCall(
      "project/list_all_minimal_data_cliented",
      data
    );
    return response;
  },

  async fetchProjectListDashboard(limit) {
    let data = {
      limit: limit,
    };
    let response = await apiCall("project/list", data);

    return response;
  },

  async fetchProjectProfile(project_id) {
    let data = {
      project_id: project_id,
    };
    let response = await apiCall("project/profile", data);

    return response;
  },
  async fetchProjectNumber(data) {
    let response = await apiCall("project/count", data);
    return response;
  },
  async fetchLowProjectNumber(data) {
    let response = await apiCall("project/count/low", data);
    return response;
  },
  async fetchMediumProjectNumber(data) {
    let response = await apiCall("project/count/medium", data);
    return response;
  },
  async fetchHighProjectNumber(data) {
    let response = await apiCall("project/count/high", data);
    return response;
  },

  async fetchProjectProgress(project_id) {
    let data = {
      project_id: project_id,
    };
    let response = await apiCall("project/progress", data);

    return response;
  },

  async UpadetProjectStatus(data) {
    let response = await apiCall("project/update_status", data);
    return response;
  },
  async createProject(
    project_name,
    project_description,
    project_overview,
    project_priority,
    client,
    startDate
  ) {
    let data = {
      project_name: project_name,
      description: project_description,
      overview: project_overview,
      priority: project_priority,
      client: client,
      start_date: startDate,
    };

    let response = await apiCall("project/create", data);
    return response;
  },

  async updateProject(
    project_id,
    project_name,
    project_description,
    project_overview,
    project_priority,
    start_date
  ) {
    let data = {
      project_id: project_id,
      project_name: project_name,
      description: project_description,
      overview: project_overview,
      priority: project_priority,
      start_date: start_date,
    };

    let response = await apiCall("project/update", data);
    return response;
  },

  async productTracker(id, type, excel_data, excel_cols) {
    let data = {
      project_id: id,
      type: type,
      excel_data: excel_data,
      excel_cols: excel_cols,
    };

    let response = await apiCall("project/product/tracker", data);
    return response;
  },

  async fetchPoleBatches(project_id) {
    let data = {
      project_id: project_id,
    };

    let response = await apiCall("project/pole/batches", data);
    return response;
  },

  async fetchOTBBatches(project_id) {
    let data = {
      project_id: project_id,
    };

    let response = await apiCall("project/otb/batches", data);
    return response;
  },
  async fetchJointBatches(project_id) {
    let data = {
      project_id: project_id,
    };

    let response = await apiCall("project/joints/batches", data);
    return response;
  },
  async fetchHardwareBatches(project_id) {
    let data = {
      project_id: project_id,
    };

    let response = await apiCall("project/hardware/batches", data);
    return response;
  },
  async fetchCrossesBatches(project_id) {
    let data = {
      project_id: project_id,
    };

    let response = await apiCall("project/cross/batches", data);
    return response;
  },
  async fetchCablesBatches(project_id) {
    let data = {
      project_id: project_id,
    };

    let response = await apiCall("project/cable/batches", data);
    return response;
  },

  async deletePoleBatch(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/pole/batch/delete", data);
    return response;
  },

  async deleteOTBBatch(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/otb/batch/delete", data);
    return response;
  },
  async deleteJointBatch(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/joints/batch/delete", data);
    return response;
  },
  async deleteHardwareBatch(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/hardware/batch/delete", data);
    return response;
  },
  async deleteCrossesBatch(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/cross/batch/delete", data);
    return response;
  },
  async deleteCablesBatch(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/cable/batch/delete", data);
    return response;
  },
  async fetchPoleBatchProducts(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/pole/products/list", data);
    return response;
  },
  async fetchOTBBatchProducts(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/otb/products/list", data);
    return response;
  },
  async fetchJointBatchProducts(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/joints/products/list", data);
    return response;
  },
  async fetchHardwareBatchProducts(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/hardware/products/list", data);
    return response;
  },
  async fetchCrossesBatchProducts(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/cross/products/list", data);
    return response;
  },
  async fetchCablesBatchProducts(batch) {
    let data = {
      batch: batch,
    };

    let response = await apiCall("project/cable/products/list", data);
    return response;
  },
  async updatePoleStatus(
    id,
    status,
    install_date,
    installed_by,
    approved_by,
    image,
    description,
    product_id
  ) {
    let data = {
      id: id,
      status: status,
      install_date: install_date,
      installed_by: installed_by,
      approved_by: approved_by,
      image: image,
      description: description,
      product_id: product_id,
    };

    let response = await apiCall("project/pole/status", data);
    return response;
  },
  async updatePoleState(
    id,
    verified_comment,
    approved,
    verified_date,
    verified_by
  ) {
    let data = {
      id: id,
      verified: "VERIFIED",
      approved: approved,
      verified_comment: verified_comment,
      verified_date: verified_date,
      verified_by: verified_by,
    };

    let response = await apiCall("project/pole/verify", data);
    return response;
  },
  async updatePlumbStatus(
    id,
    status,
    install_date,
    installed_by,
    approved_by,
    image,
    description
  ) {
    let data = {
      id: id,
      status: status,
      install_date: install_date,
      installed_by: installed_by,
      approved_by: approved_by,
      image: image,
      description: description,
    };

    let response = await apiCall("project/plumb/status", data);
    return response;
  },
  async updatePitStatus(
    id,
    pit_id,
    status,
    install_date,
    installed_by,
    approved_by,
    image,
    description
  ) {
    let data = {
      id: id,
      pit_id: pit_id,
      status: status,
      install_date: install_date,
      installed_by: installed_by,
      approved_by: approved_by,
      image: image,
      description: description,
    };

    let response = await apiCall("project/pit/status", data);
    return response;
  },

  async searchPoles(pole_id, limit = "") {
    let data = {
      pole_id: pole_id,
      limit: limit,
    };

    let response = await apiCall("project/pole/search", data);
    return response;
  },

  // JOINTS
  async updateJointStatus(
    id,
    status,
    install_date,
    installed_by,
    approved_by,
    product_id
  ) {
    let data = {
      id: id,
      status: status,
      install_date: install_date,
      installed_by: installed_by,
      approved_by: approved_by,
      product_id: product_id,
    };

    let response = await apiCall("project/joints/status", data);
    return response;
  },
  async updateCableStatus(
    id,
    status,
    install_date,
    start_length,
    end_length,
    comment,
    product_id
  ) {
    let data = {
      id: id,
      status: status,
      install_date: install_date,
      start_length: start_length,
      end_length: end_length,
      comment: comment,
      product_id: product_id,
    };

    let response = await apiCall("project/cable/status", data);
    return response;
  },

  async searchOTBs(pole_id) {
    let data = {
      pole_id: pole_id,
    };

    let response = await apiCall("project/otb/search", data);
    return response;
  },
  async searchJoints(pole_id) {
    let data = {
      pole_id: pole_id,
    };

    let response = await apiCall("project/joints/search", data);
    return response;
  },
  async searchHardware(pole_id) {
    let data = {
      pole_id: pole_id,
    };

    let response = await apiCall("project/hardware/search", data);
    return response;
  },
  async searchCrosses(pole_id) {
    let data = {
      pole_id: pole_id,
    };

    let response = await apiCall("project/cross/search", data);
    return response;
  },
  async searchCables(pole_id) {
    let data = {
      pole_id: pole_id,
    };

    let response = await apiCall("project/cable/search", data);
    return response;
  },

  async searchPolesLimit(pole_id) {
    let data = {
      pole_id: pole_id,
    };
    let response = await apiCall("project/pole/search/limit", data);
    return response;
  },

  async startInstallation(pole_id) {
    let data = {
      pole_id: pole_id,
    };
    let response = await apiCall("project/installation/start", data);
    return response;
  },

  async getPoleInfo(pole_id) {
    let data = {
      pole_id: pole_id,
    };
    let response = await apiCall("project/pole/info", data);
    return response;
  },

  async listInstallations(data) {
    let response = await apiCall("project/installationsPending/list", data);
    return response;
  },

  async clistInstallations(data) {
    let response = await apiCall("project/installationsComplete/list", data);
    return response;
  },
  async listPoles_installed_in_date_range(data) {
    let response = await apiCall(
      "project/pole/products/get_poles_installed_in_date_range",
      data
    );
    return response;
  },

  async uploadImage1(pole_id, image1, image1_desc) {
    let data = {
      pole_id: pole_id,
      image1: image1,
      image1_desc: image1_desc,
    };

    let response = await apiCall("project/poles/image/one", data);
    return response;
  },

  async uploadImage2(pole_id, image2, image2_desc) {
    let data = {
      pole_id: pole_id,
      image2: image2,
      image2_desc: image2_desc,
    };

    let response = await apiCall("project/poles/image/two", data);
    return response;
  },

  async uploadImage3(pole_id, image3, image3_desc) {
    let data = {
      pole_id: pole_id,
      image3: image3,
      image3_desc: image3_desc,
    };

    let response = await apiCall("project/poles/image/three", data);
    return response;
  },

  async uploadImage4(pole_id, image4, image4_desc) {
    let data = {
      pole_id: pole_id,
      image4: image4,
      image4_desc: image4_desc,
    };

    let response = await apiCall("project/poles/image/four", data);
    return response;
  },
  async searchActiveInstalls(searchQuery) {
    let data = {
      pole_id: searchQuery,
    };

    let response = await apiCall("project/pole/search/limit/state", data);
    return response;
  },
  async getPoleCrosses(pole_id) {
    let data = {
      pole_id: pole_id,
    };
    let response = await apiCall("project/pole/crosses", data);
    return response;
  },
  async getPoleJoints(pole_id) {
    let data = {
      pole_id: pole_id,
    };
    let response = await apiCall("project/pole/joints", data);
    return response;
  },
  async getPoleOtbs(pole_id) {
    let data = {
      pole_id: pole_id,
    };
    let response = await apiCall("project/pole/otbs", data);
    return response;
  },
  async getPoleHardware(pole_id) {
    let data = {
      pole_id: pole_id,
    };
    let response = await apiCall("project/pole/hardware", data);
    return response;
  },
  async updateCrossStatus(
    id,
    current_length,
    status,
    install_date,
    installed_by,
    approved_by,
    image,
    description,
    product_id
  ) {
    let data = {
      id: id,
      current_length: current_length,
      status: status,
      install_date: install_date,
      installed_by: installed_by,
      approved_by: approved_by,
      image: image,
      description: description,
      product_id: product_id,
    };
    let response = await apiCall("project/cross/status", data);
    return response;
  },
  async updateJointStatus(id, status, install_date) {
    let data = {
      id: id,
      status: status,
      install_date: install_date,
    };
    let response = await apiCall("project/joints/status", data);
    return response;
  },
  async updateOtbStatus(
    id,
    status,
    install_date,
    installed_by,
    approved_by,
    labeling_image,
    splicing_image,
    closed_image,
    tree_image,
    product_id
  ) {
    let data = {
      id: id,
      status: status,
      install_date: install_date,
      installed_by: installed_by,
      approved_by: approved_by,
      labeling_image: labeling_image,
      splicing_image: splicing_image,
      closed_image: closed_image,
      tree_image: tree_image,
      product_id: product_id,
    };
    let response = await apiCall("project/otb/status", data);
    return response;
  },
  async updateHardwareStatus(
    id,
    final,
    install_date,
    installed_by,
    approved_by
  ) {
    let data = {
      id: id,
      final: final,
      install_date: install_date,
      installed_by: installed_by,
      approved_by: approved_by,
    };
    let response = await apiCall("project/hardware/status", data);
    return response;
  },

  async createPole(
    project_id,
    import_batch,
    wkt_geom,
    fid,
    idd,
    id_town,
    id_neighborhood,
    pole_id,
    trunk,
    height,
    contractor
  ) {
    let data = {
      project_id: project_id,
      import_batch: import_batch,
      wkt_geom: wkt_geom,
      fid: fid,
      idd: idd,
      id_town: id_town,
      id_neighborhood: id_neighborhood,
      pole_id: pole_id,
      trunk: trunk,
      height: height,
      contractor: contractor,
    };

    let response = await apiCall("project/pole/create", data);
    return response;
  },
  async updatePole(
    poles_id,
    project_id,
    import_batch,
    wkt_geom,
    fid,
    idd,
    id_town,
    id_neighborhood,
    pole_id,
    trunk,
    height,
    contractor
  ) {
    let data = {
      poles_id: poles_id,
      project_id: project_id,
      import_batch: import_batch,
      wkt_geom: wkt_geom,
      fid: fid,
      idd: idd,
      id_town: id_town,
      id_neighborhood: id_neighborhood,
      pole_id: pole_id,
      trunk: trunk,
      height: height,
      contractor: contractor,
    };

    let response = await apiCall("project/pole/update", data);
    return response;
  },
  async createCross(
    project_id,
    import_batch,
    wkt_geom,
    fid,
    name,
    cable_attachment,
    initial_length,
    trunk,
    location,
    contractor
  ) {
    let data = {
      project_id: project_id,
      import_batch: import_batch,
      wkt_geom: wkt_geom,
      fid: fid,
      name: name,
      cable_attachment: cable_attachment,
      initial_length: initial_length,
      trunk: trunk,
      location: location,
      contractor: contractor,
    };

    let response = await apiCall("project/cross/create", data);
    return response;
  },
  async updateCross(
    cross_id,
    project_id,
    import_batch,
    wkt_geom,
    fid,
    name,
    cable_attachment,
    initial_length,
    trunk,
    location,
    contractor
  ) {
    let data = {
      cross_id: cross_id,
      project_id: project_id,
      import_batch: import_batch,
      wkt_geom: wkt_geom,
      fid: fid,
      name: name,
      cable_attachment: cable_attachment,
      initial_length: initial_length,
      trunk: trunk,
      location: location,
      contractor: contractor,
    };

    let response = await apiCall("project/cross/update", data);
    return response;
  },
  async createCable(
    project_id,
    import_batch,
    fid,
    city,
    name,
    template,
    gis_length,
    real_length,
    role,
    cores,
    trunk,
    contractor
  ) {
    let data = {
      project_id: project_id,
      import_batch: import_batch,
      fid: fid,
      city: city,
      name: name,
      template: template,
      gis_length: gis_length,
      real_length: real_length,
      role: role,
      cores: cores,
      trunk: trunk,
      contractor: contractor,
    };

    let response = await apiCall("project/cable/create", data);
    return response;
  },
  async updateCable(
    cable_id,
    project_id,
    import_batch,
    fid,
    city,
    name,
    template,
    gis_length,
    real_length,
    role,
    cores,
    trunk,
    contractor
  ) {
    let data = {
      cable_id: cable_id,
      project_id: project_id,
      import_batch: import_batch,
      fid: fid,
      city: city,
      name: name,
      template: template,
      gis_length: gis_length,
      real_length: real_length,
      role: role,
      cores: cores,
      trunk: trunk,
      contractor: contractor,
    };

    let response = await apiCall("project/cable/update", data);
    return response;
  },
  async createHardware(
    project_id,
    import_batch,
    pole_id,
    nb_cables,
    nb_ofc12,
    nb_ofc144,
    nb_ofc48,
    trunk,
    estimated_hardware,
    Nb_spriral_11,
    Nb_spriral_15,
    Nb_DeadN_11,
    Nb_DeadN_15,
    contractor
  ) {
    let data = {
      project_id: project_id,
      import_batch: import_batch,
      pole_id: pole_id,
      nb_cables: nb_cables,
      nb_ofc12: nb_ofc12,
      nb_ofc144: nb_ofc144,
      nb_ofc48: nb_ofc48,
      trunk: trunk,
      estimated_hardware: estimated_hardware,
      Nb_spriral_11: Nb_spriral_11,
      Nb_spriral_15: Nb_spriral_15,
      Nb_DeadN_11: Nb_DeadN_11,
      Nb_DeadN_15: Nb_DeadN_15,
      contractor: contractor,
    };

    let response = await apiCall("project/hardware/create", data);
    return response;
  },
  async updateHardware(
    hardware_id,
    project_id,
    import_batch,
    pole_id,
    nb_cables,
    nb_ofc12,
    nb_ofc144,
    nb_ofc48,
    trunk,
    estimated_hardware,
    Nb_spriral_11,
    Nb_spriral_15,
    Nb_DeadN_11,
    Nb_DeadN_15,
    contractor
  ) {
    let data = {
      hardware_id: hardware_id,
      project_id: project_id,
      import_batch: import_batch,
      pole_id: pole_id,
      nb_cables: nb_cables,
      nb_ofc12: nb_ofc12,
      nb_ofc144: nb_ofc144,
      nb_ofc48: nb_ofc48,
      trunk: trunk,
      estimated_hardware: estimated_hardware,
      Nb_spriral_11: Nb_spriral_11,
      Nb_spriral_15: Nb_spriral_15,
      Nb_DeadN_11: Nb_DeadN_11,
      Nb_DeadN_15: Nb_DeadN_15,
      contractor: contractor,
    };

    let response = await apiCall("project/hardware/update", data);
    return response;
  },
  async getHardwareInfo(hardware_id) {
    let data = {
      hardware_id: hardware_id,
    };
    let response = await apiCall("project/hardware/info", data);
    return response;
  },

  async addOtb(
    id,
    batch,
    wkt_geom,
    fid,
    city,
    name,
    pole_id,
    template,
    nb_splitter,
    trunk,
    comment,
    is_derivation,
    cable_entree,
    nb_splice_est_sc,
    nb_splice_est_otb,
    otb_desat,
    contractor
  ) {
    let data = {
      project_id: id,
      import_batch: batch,
      wkt_geom: wkt_geom,
      fid: fid,
      city: city,
      name: name,
      pole_id: pole_id,
      template: template,
      nb_splitter: nb_splitter,
      trunk: trunk,
      comment: comment,
      is_derivation: is_derivation,
      cable_entree: cable_entree,
      nb_splice_est_sc: nb_splice_est_sc,
      nb_splice_est_otb: nb_splice_est_otb,
      otb_desat: otb_desat,
      contractor: contractor,
    };
    let response = await apiCall("project/otb/create", data);
    return response;
  },
  async updateOtb(
    otb_id,
    id,
    batch,
    wkt_geom,
    fid,
    city,
    name,
    pole_id,
    template,
    nb_splitter,
    trunk,
    is_derivation,
    cable_entree,
    nb_splice_est_sc,
    nb_splice_est_otb,
    otb_desat,
    contractor
  ) {
    let data = {
      otb_id: otb_id,
      project_id: id,
      import_batch: batch,
      wkt_geom: wkt_geom,
      fid: fid,
      city: city,
      name: name,
      pole_id: pole_id,
      template: template,
      nb_splitter: nb_splitter,
      trunk: trunk,
      is_derivation: is_derivation,
      cable_entree: cable_entree,
      nb_splice_est_sc: nb_splice_est_sc,
      nb_splice_est_otb: nb_splice_est_otb,
      otb_desat: otb_desat,
      contractor: contractor,
    };
    let response = await apiCall("project/otb/update", data);
    return response;
  },

  async addJoint(
    project_id,
    import_batch,
    wkt_geom,
    fid,
    city,
    name,
    pole_location,
    manhole_location,
    template,
    trunk,
    cores,
    nb_splice_est,
    nb_splice_est_12,
    nb_splice_est_48,
    joint_droit,
    nb_splice_joint,
    cable_entree
  ) {
    let data = {
      project_id: project_id,
      import_batch: import_batch,
      wkt_geom: wkt_geom,
      fid: fid,
      city: city,
      name: name,
      pole_location: pole_location,
      manhole_location: manhole_location,
      template: template,
      trunk: trunk,
      cores: cores,
      nb_splice_est: nb_splice_est,
      nb_splice_est_12: nb_splice_est_12,
      nb_splice_est_48: nb_splice_est_48,
      joint_droit: joint_droit,
      nb_splice_joint: nb_splice_joint,
      cable_entree: cable_entree,
    };
    let response = await apiCall("project/joints/create", data);
    return response;
  },

  async updateJoint(
    joint_id,
    project_id,
    import_batch,
    wkt_geom,
    fid,
    city,
    name,
    pole_location,
    manhole_location,
    template,
    trunk,
    cores,
    nb_splice_est,
    nb_splice_est_12,
    nb_splice_est_48,
    joint_droit,
    nb_splice_joint,
    cable_entree
  ) {
    let data = {
      joint_id: joint_id,
      project_id: project_id,
      import_batch: import_batch,
      wkt_geom: wkt_geom,
      fid: fid,
      city: city,
      name: name,
      pole_location: pole_location,
      manhole_location: manhole_location,
      template: template,
      trunk: trunk,
      cores: cores,
      nb_splice_est: nb_splice_est,
      nb_splice_est_12: nb_splice_est_12,
      nb_splice_est_48: nb_splice_est_48,
      joint_droit: joint_droit,
      nb_splice_joint: nb_splice_joint,
      cable_entree: cable_entree,
    };
    let response = await apiCall("project/joints/update", data);
    return response;
  },

  async filterInstallations(project_id, task, team, status) {
    let data = {
      project_id: project_id,
      task: task,
      team: team,
      status: status,
    };

    let response = await apiCall("project/installations/filter", data);
    return response;
  },
};
