import React, { useContext, useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import AuthContext from "../../Context/AuthContext";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import ajaxProject from "../../util/remote/ajaxProject";

function InvoiceCreate(props) {
  const { userId } = useContext(AuthContext);
  const [projectId, setProjectId] = useState("");
  const [LedgerID_credited, setLedgerID_credited] = useState("");
  const [LedgerID_debited, setLedgerID_debited] = useState("");
  const [lopno, setLpoNo] = useState("");
  const [refno, setRefNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([
    { description: "", quantity: "", rate: "", unit: "", amount: "" },
  ]);
  const [date, setDate] = useState("");
  const [projects, setProjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [vatApplied, setVatApplied] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
    getProjects();
  }, []);

  const formatMoney = (value) => {
    return value
      ? value.toLocaleString("en-UG", {
          style: "currency",
          currency: "UGX",
          minimumFractionDigits: 2,
        })
      : "UGX 0.00";
  };

  const calculateTotalAmount = () => {
    return rows.reduce(
      (total, row) => total + (parseFloat(row.amount) || 0),
      0
    );
  };

  const calculateVAT = () => {
    return vatApplied ? calculateTotalAmount() * 0.18 : 0;
  };

  const calculateGrandTotal = () => {
    return calculateTotalAmount() + calculateVAT();
  };

  const allRowsFilled = () => {
    return rows.every(
      (row) =>
        row.description.trim() && row.quantity > 0 && row.rate > 0 && row.unit
    );
  };
  const handleInvoiceAddition = async (e) => {
    e.preventDefault();
    if (userId > 0 && date && projectId && rows.length > 0 && allRowsFilled()) {
      setLoading(true);
      const outputData = rows.map(
        ({ description, quantity, rate, amount, unit }) => ({
          description,
          quantity,
          rate,
          amount,
          unit,
        })
      );
      const data = {
        project_id: projectId,
        LedgerID_debited: LedgerID_debited,
        LedgerID_credited: LedgerID_credited,
        ref_no: refno,
        lpo_no: lopno,
        date_added: date,
        vat_applied: vatApplied,
        details: outputData,
        added_by: userId,
      };
      const server_response = await ajaxAccounting.createInvoice(data);
      setLoading(false);

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        resetForm();
        props.function();
      } else {
        toast.error(server_response.message);
      }
    } else {
      setErrorMessage("Complete all fields required.");
      toast.error("Complete all fields and try again");
    }
  };

  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    }
  };

  // -----
  const [debitledgers, setdebitledgers] = useState([]);
  const getDebitlegders = async () => {
    const response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    setdebitledgers(response.status === "OK" ? response.details : []);
  };
  // --------
  const [creditLedger, setCreditLedger] = useState([]);
  const getCreditlegders = async () => {
    const response = await ajaxAccounting.getALlLedgersMinData_cash_or_bank();
    setCreditLedger(response.status === "OK" ? response.details : []);
  };

  useEffect(() => {
    getDebitlegders();
    getCreditlegders();
  }, []);

  const handleAddRow = () => {
    const lastRow = rows[rows.length - 1];
    if (
      !lastRow.description ||
      !lastRow.quantity ||
      !lastRow.rate ||
      !lastRow.unit
    ) {
      toast.error("Please complete the current row before adding a new one.");
      return;
    }
    setRows([
      ...rows,
      { description: "", quantity: "", rate: "", unit: "", amount: "" },
    ]);
    setErrorMessage("");
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, idx) => idx !== index);
    setRows(
      updatedRows.length === 0
        ? [{ description: "", quantity: "", rate: "", unit: "", amount: "" }]
        : updatedRows
    );
    setErrorMessage("");
  };

  const handleFieldChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === "quantity" || field === "rate") {
      const quantity = parseFloat(updatedRows[index].quantity) || 0;
      const rate = parseFloat(updatedRows[index].rate) || 0;
      updatedRows[index].amount = quantity * rate;
    }

    setRows(updatedRows);
  };

  const resetForm = () => {
    setProjectId("");
    setLpoNo("");
    setRefNo("");
    setDate(new Date().toISOString().split("T")[0]);
    setRows([
      { description: "", quantity: "", rate: "", unit: "", amount: "" },
    ]);
    setErrorMessage("");
    setVatApplied(false);
  };

  return (
    <>
      <Toaster />
      <div className="card custom-card">
        <div className="card-body">
          <h6 className="card-title mb-4">Record Invoice</h6>
          <form onSubmit={handleInvoiceAddition}>
            <div className="row">
              <div className="form-group col-md-4 col-lg-4 mt-1">
                <label>
                  Select Project<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  onChange={(e) => setProjectId(e.id)}
                  getOptionLabel={(option) => option.project_name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={projects}
                  value={
                    projectId
                      ? projects.find((value) => value.id === projectId)
                      : ""
                  }
                />
              </div>
              <div className="mb-4 col-md-4">
                <label>
                  Select Account To Debit{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  onChange={(e) => setLedgerID_debited(e.id)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={debitledgers}
                  value={
                    debitledgers.find((d) => d.id === LedgerID_debited) || ""
                  }
                />
              </div>
              <div className="mb-4 col-md-4">
                <label>
                  Select Account To Credit{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  onChange={(e) => setLedgerID_credited(e.id)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={creditLedger}
                  value={
                    creditLedger.find((c) => c.id === LedgerID_credited) || ""
                  }
                />
              </div>

              <div className="mb-4 col-md-4">
                <label>
                  Ref NO:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={refno}
                  onChange={(e) => setRefNo(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-4">
                <label>
                  LPO NO:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={lopno}
                  onChange={(e) => setLpoNo(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-4">
                <label>
                  Date:<span className="tx-danger">*</span>
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="form-control "
                />
              </div>

              {/* VAT Checkbox */}
              <div className="mb-4 col-md-12">
                <label>
                  <input
                    type="checkbox"
                    checked={vatApplied}
                    onChange={(e) => setVatApplied(e.target.checked)}
                    className="mr-2"
                  />
                  Apply VAT (18%)
                </label>
              </div>

              {/* Invoice Table */}
              <div className="mb-4 col-md-12">
                <hr />
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Description</th>
                      <th>Unit</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                      <th>Operations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <textarea
                            className="form-control"
                            value={row.description}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={row.unit}
                            onChange={(e) =>
                              handleFieldChange(index, "unit", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            value={row.rate}
                            onChange={(e) =>
                              handleFieldChange(index, "rate", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            value={row.quantity}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={formatMoney(row.amount || 0)}
                            readOnly
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleDeleteRow(index)}
                            type="button">
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="4">
                        <button
                          type="button"
                          onClick={handleAddRow}
                          className="btn btn-primary btn-sm mr-2"
                          disabled={loading}>
                          Add Row
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Totals */}
              <div className="mb-4 col-md-12">
                <div className="row">
                  <div
                    className="col-lg-3"
                    style={{
                      border: "1px groove black",
                      margin: "10px",
                      padding: "10px",
                    }}>
                    <p>Total (No VAT): {formatMoney(calculateTotalAmount())}</p>
                  </div>{" "}
                  {vatApplied && (
                    <>
                      <div
                        className="col-lg-3"
                        style={{
                          border: "1px groove black",
                          margin: "10px",
                          padding: "10px",
                        }}>
                        <p>VAT (18%): {formatMoney(calculateVAT())}</p>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{
                          border: "1px groove black",
                          margin: "10px",
                          padding: "10px",
                        }}>
                        <p>Grand Total: {formatMoney(calculateGrandTotal())}</p>
                      </div>
                    </>
                  )}
                </div>{" "}
              </div>

              <div className="mb-4 col-md-12">
                <button
                  type="submit"
                  style={{ width: "100%" }}
                  className="btn btn-success"
                  disabled={!allRowsFilled() || loading}>
                  {loading ? "Adding..." : "Record Invoice"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default InvoiceCreate;
