import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import TableHeader from "../../Components/Common/TableHeader";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ProjectPolesInstalledDaterange() {
  const [loading, setLoading] = useState(false);
  const [installations, setIstallations] = useState(false);

  const { projectID, startDate, endDate } = useParams();
  useEffect(() => {
    listInstallations();
  }, []);

  const listInstallations = async () => {
    var data = {
      projectID: projectID * 1,
      to_date: endDate,
      start_date: startDate,
    };
    setLoading(true);
    const server_response = await ajaxProject.listPoles_installed_in_date_range(
      data
    );
    setLoading(false);

    if (server_response.status === "OK") {
      setIstallations(server_response.details);
    } else {
      setTimeout(() => {
        toast.error(server_response.message);
      }, 5000);
    }
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = " Installed Poles";

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table (add more if needed)
    const columns = [
      "No.",
      "Pole ID",
      "Project Name",
      "Installation Date",
      "neighborhood",
    ];
    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`poles_installed${new Date().toLocaleDateString()}.pdf`);
  };

  return (
    <AppContainer title="Poles Installed in date Range">
      <Toaster />

      <div className="card custom-card">
        <div className="card-body">
          <TableHeader
            viewButton={
              <>
                <a
                  href="#"
                  onClick={exportToPDF}
                  className="btn btn-secondary btn-sm mr-2"
                  style={{ float: "right" }}>
                  Export to PDF
                </a>
              </>
            }
          />
          {loading && <Loader />}
          <div className="border-top mt-3"></div>
          <div className="table-responsive">
            <table className="table  table-hover">
              <thead>
                <th>#NO</th>
                <th>Pole ID</th>
                <th>Project Name</th>
                <th>Installation Date</th>
                <th>neighborhood</th>
              </thead>
              <tbody>
                {Array.isArray(installations) &&
                  installations.map((item, key) => (
                    <>
                      <tr>
                        <td>{key + 1}</td>
                        <td>{item.pole_id}</td>
                        <td>{item.project_name}</td>
                        <td>{item.install_date}</td>
                        <td>{item.id_neighborhood}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default ProjectPolesInstalledDaterange;
