import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RenderSecure } from "../../util/script/RenderSecure";
import AuthContext from "../../Context/AuthContext";
import InstallationProjectSelectPendingModal from "../../Pages/Installations/InstallationProjectSelectPendingModal";
import useStateCallback from "../../util/customHooks/useStateCallback";
import InstallationProjectSelectCompleteModal from "../../Pages/Installations/InstallationProjectSelectCompleteModal";
import ProjectInstalledPolesModal from "../../Pages/Installations/ProjectInstalledPolesModal";

export default function Sidebar() {
  const toggleDropdown = (e) => {
    e.preventDefault();
    if (e.target.parentElement.parentElement.classList.contains("show")) {
      e.target.parentElement.parentElement.classList.remove("show");
    } else {
      e.target.parentElement.parentElement.classList.add("show");
    }
  };

  const hoverSidebar = () => {
    const side = document.getElementsByClassName("side-menu");
    let body = document.getElementsByTagName("body");

    if (body[0].classList.contains("main-sidebar-hide")) {
      body[0].classList.add("main-sidebar-open");
    }
  };

  const unHoverSidebar = () => {
    const side = document.getElementsByClassName("side-menu");
    let body = document.getElementsByTagName("body");

    if (body[0].classList.contains("main-sidebar-hide")) {
      body[0].classList.remove("main-sidebar-open");
    }
  };

  const [pendingInstallations, setPendingInstallations] =
    useStateCallback(false);

  const handlePending_installations = () => {
    setPendingInstallations(false, () =>
      setPendingInstallations(
        <InstallationProjectSelectPendingModal
          function={setPendingInstallations}
        />
      )
    );
  };
  const [completed_installation, SetCompleteInsatllations] =
    useStateCallback(false);

  const handlecomplete_installations = () => {
    SetCompleteInsatllations(false, () =>
      SetCompleteInsatllations(
        <InstallationProjectSelectCompleteModal
          setComplete={SetCompleteInsatllations}
        />
      )
    );
  };
  const [ProjectInstalledPOles, setProjectInstalledPoles] =
    useStateCallback(false);

  const handleProjectInstalledPOles = () => {
    setProjectInstalledPoles(false, () =>
      setProjectInstalledPoles(
        <ProjectInstalledPolesModal setComplete={setProjectInstalledPoles} />
      )
    );
  };
  return (
    <div
      style={{
        color: "red",
      }}
      className="main-sidebar main-sidebar-sticky side-menu"
      onMouseLeave={unHoverSidebar}
      onMouseOver={hoverSidebar}>
      {pendingInstallations}
      {completed_installation}
      {ProjectInstalledPOles}
      <div className="sidemenu-logo">
        <a className="main-logo" href="/">
          <img
            src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
            className="header-brand-img desktop-logo"
            alt="logo"
          />
          <img
            src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
            className="header-brand-img icon-logo"
            alt="logo1"
          />
          <img
            src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
            className="header-brand-img desktop-logo theme-logo"
            alt="logo1"
          />
          <img
            src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
            className="header-brand-img icon-logo theme-logo"
            alt="logo"
          />
        </a>
      </div>

      <div
        className="main-sidebar-body"
        style={{
          overflowY: "scroll" /* Add vertical scrolling */,
          overflowX: "hidden" /* Hide horizontal scrolling */,
        }}>
        <ul className="nav">
          <li className="nav-item show">
            <Link className="nav-link" to="/">
              <i class="fa-solid fa-gauge"></i>
              <span className="sidemenu-label">Dashboard</span>
            </Link>
          </li>

          <RenderSecure code="VIEW-PROJECT">
            <li className="nav-item">
              <Link className="nav-link" to="/stores">
                <i class="fa-solid fa-store"></i>
                <span className="sidemenu-label">Stores</span>
              </Link>
            </li>
          </RenderSecure>
          <RenderSecure code="VIEW-PROJECT">
            <li className="nav-item">
              <Link className="nav-link" to="/projects">
                <i class="fa-solid fa-diagram-project"></i>
                <span className="sidemenu-label">Projects</span>
              </Link>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-PROJECT">
            <li className="nav-item">
              <Link className="nav-link" to="/tasks">
                <i class="fa-solid fa-list-check"></i>
                <span className="sidemenu-label">Tasks</span>
              </Link>
            </li>
          </RenderSecure>

          <li className="nav-item">
            <a onClick={toggleDropdown} className="nav-link with-sub" href="#">
              <i
                style={{ position: "absolute", left: 4, fontSize: 18 }}
                className="fa fa-screwdriver fa-flip-horizontal"></i>
              <i className="fa fa-wrench "></i>
              <span className="sidemenu-label">Installations</span>
              <i className="angle fe fe-chevron-right"></i>
            </a>

            <ul className="nav-sub">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={handlePending_installations}>
                  <i className="fa fa-exclamation"></i>
                  <span className="sidemenu-label">Pending Installations</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={handlecomplete_installations}
                  to="#">
                  <i className="fa fa-check-circle"></i>
                  <span className="sidemenu-label">Complete Installations</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={handleProjectInstalledPOles}
                  to="#">
                  <i class="fa-solid fa-signs-post"></i>
                  <span className="sidemenu-label">
                    Project Installed Poles
                  </span>
                </Link>
              </li>
            </ul>
          </li>

          <RenderSecure code="VIEW-TEAM">
            <li className="nav-item">
              <Link className="nav-link" to="/teams">
                <i class="fa-solid fa-people-group"></i>
                <span className="sidemenu-label">Teams</span>
              </Link>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-CLIENT">
            <li className="nav-item">
              <a
                onClick={toggleDropdown}
                className="nav-link with-sub"
                href="#">
                <i className="fa fa-user-tie"></i>
                <span className="sidemenu-label">Clients</span>
                <i className="angle fe fe-chevron-right"></i>
              </a>
              <ul className="nav-sub">
                <li className="nav-item">
                  <Link className="nav-link" to="/add-client">
                    <i className="fe fe-user-plus"></i>
                    <span className="sidemenu-label">Add Client</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/clients">
                    <i className="fa fa-user-tie"></i>
                    <span className="sidemenu-label">View Clients</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li className="nav-item">
							<Link className="nav-link" to="/visits"><i className="fa fa-map-marker-alt"></i><span className="sidemenu-label">Visits</span></Link>
						</li> */}
          </RenderSecure>

          {/* <RenderSecure code="VIEW-REGION">

						<li className="nav-item">
							<Link className="nav-link" to="/regions"><i className="fe fe-maximize"></i><span className="sidemenu-label">Regions</span></Link>
						</li>

					</RenderSecure> */}

          <RenderSecure code="VIEW-PRODUCTS">
            <li className="nav-item">
              <Link className="nav-link" to="/products">
                <i className="fe fe-package"></i>
                <span className="sidemenu-label">Products</span>
              </Link>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-INVENTORY">
            <li className="nav-item">
              <a
                href="#"
                onClick={toggleDropdown}
                className="nav-link with-sub">
                <i className="fa fa-warehouse"></i>
                <span className="sidemenu-label">Inventory</span>
                <i className="angle fe fe-chevron-right"></i>
              </a>

              <ul className="nav-sub">
                <li className="nav-item">
                  <Link className="nav-link" to="/inventory">
                    <i className="fe fe-eye"></i>
                    <span className="sidemenu-label">View Inventory</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/delivery-notes">
                    <i className="fe fe-eye"></i>
                    <span className="sidemenu-label">View Delivery Notes</span>
                  </Link>
                </li>
                <RenderSecure code="ADD-INVENTORY-INPUT">
                  <li className="nav-item">
                    <Link className="nav-link" to="/inventory/create">
                      <i
                        className="fe fe-plus-circle"
                        style={{ color: "green" }}></i>
                      <span className="sidemenu-label">Record Input</span>
                    </Link>
                  </li>
                </RenderSecure>

                <RenderSecure code="ADD-INVENTORY-OUTPUT">
                  <li className="nav-item">
                    <Link className="nav-link" to="/inventory/output">
                      <i
                        className="fe fe-plus-circle"
                        style={{ color: "red" }}></i>
                      <span className="sidemenu-label">Record Output</span>
                    </Link>
                  </li>
                </RenderSecure>
                <RenderSecure code="ADD-INVENTORY-OUTPUT">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/inventory/change_inventory_store">
                      <i
                        className="fe fe-plus-circle"
                        style={{ color: "orange" }}></i>
                      <span className="sidemenu-label">
                        Change Inevntotry store
                      </span>
                    </Link>
                  </li>
                </RenderSecure>
              </ul>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-PAYMENT">
            <li className="nav-item">
              <a
                href="#"
                onClick={toggleDropdown}
                className="nav-link with-sub">
                <i class="fa-solid fa-wallet" />
                <span className="sidemenu-label">Payments</span>
                <i className="angle fe fe-chevron-right"></i>
              </a>

              <ul className="nav-sub">
                <li className="nav-item">
                  <Link className="nav-link" to="/team-payments">
                    <i className="fa fa-dollar-sign"></i>
                    <span className="sidemenu-label">Team Payments</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/payments">
                    <i className="fa fa-dollar-sign"></i>
                    <span className="sidemenu-label">Employee Payments</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-REPORT">
            <li className="nav-item">
              <a
                onClick={toggleDropdown}
                className="nav-link with-sub"
                href="#">
                <i className="fa fa-chart-line"></i>
                <span className="sidemenu-label">Reports</span>
                <i className="angle fe fe-chevron-right"></i>
              </a>
              <ul className="nav-sub">
                <li className="nav-item">
                  <Link className="nav-link" to="/reports/installations">
                    <i
                      style={{ position: "absolute", left: 4, fontSize: 18 }}
                      className="fa fa-screwdriver fa-flip-horizontal"></i>
                    <i className="fa fa-wrench "></i>
                    <span className="sidemenu-label">Installations Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/reports/inventory">
                    <i className="fa fa-warehouse"></i>
                    <span className="sidemenu-label">Inventory Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/reports/payment">
                    <i className="fa fa-dollar-sign"></i>
                    <span className="sidemenu-label">Payments Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/reports/rollcall">
                    <i className="fe fe-user-check"></i>
                    <span className="sidemenu-label">Rollcall Report</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>
          <RenderSecure code="VIEW-USER">
            <li className="nav-item">
              <a
                onClick={toggleDropdown}
                className="nav-link with-sub"
                href="#">
                <i className="fe fe-users"></i>
                <span className="sidemenu-label">Users</span>
                <i className="angle fe fe-chevron-right"></i>
              </a>
              <ul className="nav-sub">
                <li className="nav-item">
                  <Link className="nav-link" to="/add-user">
                    <i className="fe fe-user-plus"></i>
                    <span className="sidemenu-label">Add User</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/users">
                    <i className="fe fe-users"></i>
                    <span className="sidemenu-label">View Users</span>
                  </Link>
                </li>
                <RenderSecure code="VIEW-ROLE">
                  <li className="nav-item">
                    <Link className="nav-link" to="/roles">
                      <i className="fe fe-settings"></i>
                      <span className="sidemenu-label">View Roles</span>
                    </Link>
                  </li>
                </RenderSecure>
                <RenderSecure code="VIEW-PERMISSION">
                  <li className="nav-item">
                    <Link className="nav-link" to="/permissions">
                      <i className="fe fe-lock"></i>
                      <span className="sidemenu-label">View Permissions</span>
                    </Link>
                  </li>
                </RenderSecure>
              </ul>
            </li>
          </RenderSecure>
          <li className="nav-item">
            <Link className="nav-link" to="/meeting-records">
              <i className="fe fe-list"></i>
              <span className="sidemenu-label">Meeting Records</span>
            </Link>
          </li>
          {/* <li className="nav-item">
							<Link className="nav-link" to="/reports"><i className="fa fa-chart-bar"></i> <span className="sidemenu-label">Reports</span></Link>
						</li> */}
        </ul>
      </div>
    </div>
  );
}
