import { useContext, useEffect, useState } from "react";
import SystemModal from "../Components/Common/SystemModal";
import Loader from "../Components/Common/Loader";
import { toast } from "react-hot-toast";
import Select from "react-select";
import ajaxStores from "../util/remote/ajaxStores";
import ajaxUser from "../util/remote/ajaxUser";

const CreateStores = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [manager, setManager] = useState("");

  const [managers, setManagers] = useState("");

  const handleAdd = async (e) => {
    e.preventDefault();
    // Form validation
    if (name.trim() === "" || location.trim() === "" || manager.trim() === "") {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);
      try {
        var data = {
          name: name,
          location: location,
          manager_id: manager,
        };
        const server_response = await ajaxStores.createStore(data);
        setLoading(false);
        if (server_response.status === "OK") {
          toast.success(server_response.message);
          resetForm();
        } else {
          toast.error(server_response.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  useEffect(() => {
    fetch_storeManagers();
  }, []);

  const fetch_storeManagers = async () => {
    const server_response = await ajaxUser.fetchStoreManagers();
    if (server_response.status === "OK") {
      setManagers(server_response.details);
    } else {
      setManagers("404");
    }
  };

  const resetForm = () => {
    setName("");
    setLocation("");
    setManager("");
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Save store
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add New Store"
      id="model-new-store"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Select manager</label>
        <Select
          onChange={(e) => setManager(e.id)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(managers) ? managers : []}
          value={
            Array.isArray(managers) &&
            managers.find((value) => value.id === manager)
          }
        />
      </div>

      <div className="mb-4 col-12">
        <label htmlFor="">
          Store Name:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4 col-12">
        <label htmlFor="">
          Store storeLocation:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default CreateStores;
