import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import ajaxProject from "../../util/remote/ajaxProject";
import Select from "react-select";
import AuthContext from "../../Context/AuthContext";

const CreateDeposit = (props) => {
  const [loading, setLoading] = useState(false);
  const [amount_out, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [projectID, setProjectID] = useState("");
  const [LedgerID_credited, setLedgerID_credited] = useState("");
  const [LedgerID_debited, setLedgerID_debited] = useState("");

  const { userId } = useContext(AuthContext);

  // useEffect(() => {
  //   const today = new Date().toISOString().split("T")[0];
  //   setDate(today);
  // }, []);

  useEffect(() => {
    getDebitledgers();
    getCreditlegders();
    getProjects();
  }, []);

  const [Debitledgers, setDebitledgers] = useState([]);
  const [creditLedger, setCreditLedger] = useState([]);
  const [projects, setProjects] = useState([]);

  const getDebitledgers = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    setDebitledgers(
      server_response.status === "OK" ? server_response.details : []
    );
  };

  const getCreditlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_cash_or_bank();
    setCreditLedger(
      server_response.status === "OK" ? server_response.details : []
    );
  };

  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    setProjects(server_response.status === "OK" ? server_response.details : []);
  };

  const handleAdd = async (e, controls) => {
    e.preventDefault();

    // Check if required fields are filled
    if (
      projectID === "" ||
      LedgerID_credited === "" ||
      LedgerID_debited === "" ||
      amount_out === "" ||
      description === "" ||
      date === ""
    ) {
      toast.error("Please fill in all required fields");
      return;
    }

    if (amount_out > 0) {
      setLoading(true);
      const data = {
        employee: props.employee,
        amount_out: amount_out,
        description: description,
        date_added: date,
        projectID: projectID,
        LedgerID_credited,
        LedgerID_debited,
        created_by: userId,
      };

      const server_response = await ajaxAccounting.createDepositTransaction(
        data
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setAmount("");
        setDescription("");
        setDate(new Date().toISOString().split("T")[0]);
        setProjectID("");
        setLedgerID_credited("");
        setLedgerID_debited("");
        controls.close();
        toast.success(server_response.message);
        // props.f(props.employee);
        // props.g(props.employee);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) =>
    loading ? (
      <Loader />
    ) : (
      <>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={controls.close}>
          Close
        </button>
        <button
          type="button"
          className="btn ripple btn-success"
          onClick={(e) => handleAdd(e, controls)}>
          Save Deposit
        </button>
      </>
    );

  return (
    <SystemModal
      title="Add New Payment Deposit"
      id="model-new-paydeposit"
      size="lg"
      footer={RenderFooter}>
      <div className="row">
        <div className="mb-4 col-md-6">
          <label>
            Select Project <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setProjectID(e.id)}
            getOptionLabel={(option) => option.project_name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={projects}
            value={projects.find((proj) => proj.id === projectID) || null}
          />
        </div>
        <div className="mb-4 col-md-6">
          <label>
            Select Account To Debit <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setLedgerID_debited(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={Debitledgers}
            value={
              Debitledgers.find((ledger) => ledger.id === LedgerID_debited) ||
              null
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label>
            Select Account To Credit <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setLedgerID_credited(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={creditLedger}
            value={
              creditLedger.find((ledger) => ledger.id === LedgerID_credited) ||
              null
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label>
            Amount <span style={{ color: "red" }}>*</span>
          </label>
          <input
            onChange={(e) => setAmount(e.target.value)}
            value={amount_out}
            type="number"
            className="form-control"
          />
        </div>
        <div className="mb-4 col-md-6">
          <label>
            Description <span style={{ color: "red" }}>*</span>
          </label>
          <input
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            type="text"
            className="form-control"
          />
        </div>
        <div className="mb-4 col-md-6">
          <label>
            Date Paid <span style={{ color: "red" }}>*</span>
          </label>
          <input
            onChange={(e) => setDate(e.target.value)}
            value={date}
            type="date"
            className="form-control"
          />
        </div>
      </div>
    </SystemModal>
  );
};

export default CreateDeposit;
