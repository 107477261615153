import React, { useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

function BalanceSheetsStatememnt(props) {
  const [loading, setLoading] = useState(false);
  const [start_date, setStart_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [projectID, setProjectID] = useState("");
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTo_date(today);
  }, []);

  // ===============
  const [project_details, setProject_details] = useState("");
  const [Assets, setAssets] = useState("");
  const [Liabilities_total, setLiabilities_total] = useState("");
  const [Liabilities, setLiabilities] = useState("");
  const [Assets_total, setAssets_total] = useState("");
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      const allOption = { id: "", project_name: "All Projects" };
      setProjects([allOption, ...server_response.details]);
    } else {
      setProjects([{ id: "", project_name: "All Projects" }]);
    }
  };

  useEffect(() => {
    getProjects();
    handleAdd();
  }, []);

  const handleAdd = async (e) => {
    setLoading(true);
    var data = {
      to_date: to_date,
      start_date: start_date,
      projectID: projectID,
    };
    const server_response = await ajaxAccounting.getProjectsBalanceSheetReport(
      data
    );

    setLoading(false);
    if (server_response.status === "OK") {
      setAssets(server_response.details.Assets);
      setLiabilities_total(server_response.details.Liabilities_total);
      setLiabilities(server_response.details.Liabilities);
      setAssets_total(server_response.details.Assets_total);
      setProject_details(server_response.details.project_name);
    } else {
      setAssets("");
      setLiabilities_total("");
      setLiabilities("");
      setAssets_total("");
    }
  };

  // Printing functionality
  const handlePrint = (e) => {
    e.preventDefault();

    // Company header information with image onload
    const companyHeader = `
      <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
        <div style="width: 20%;">
          <img
            style="position: absolute; height: 100px; width: 200px;"
            src="${process.env.PUBLIC_URL}/template/assets/images/bricote_logo.png"
            alt="Logo"
          />
        </div>
        <div style="width: 80%; line-height: 6px; text-align: center;">
          <h3>BERICOT AFRICA LIMITED</h3>
          <p style="font-size: 14px;">P.O.BOX 28775 KAMPALA</p>
          <p style="font-size: 14px;">PLOT 79B MAKAMBA ROAD</p>
          <p style="font-size: 14px;">TEL: +256701666160</p>
          <p style="font-size: 14px;">EMAIL: info@bericot.africa</p>
         
        </div>
      </div>
      <hr style="border: 1px solid black; margin-bottom: 20px;" />
    `;

    const printContents = document.getElementById("printablediv").innerHTML;
    const originalTitle = document.title;
    const newWindow = window.open("", "_blank");

    // Construct the printable content
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>${originalTitle} - Print</title>
                    <style>
            /* Include the print-specific styles here */
            @media print {
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                width: 100%;
                height: auto;
              }

              .card-body {
                padding: 20px;
                width: 100%;
              }

              #printablediv {
                width: 100%;
                margin: 0;
                padding: 0;
              }

              img {
                width: 200px;
                height: auto;
              }

              h3, h4, h1 {
                text-align: center;
                font-size: 18px;
              }

              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
                font-size: 14px;
              }

              table th, table td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }

              table th {
                background-color: #f2f2f2;
              }

              .no-print {
                display: none;
              }

              .page-break {
                page-break-before: always;
              }

              .total-section td {
                font-weight: bold;
                text-align: right;
              }
            }
          </style>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 0; padding: .5cm; width: 21cm; height: 29.7cm;">
          ${companyHeader}
          ${printContents}
          <script>
            // Ensure the image is loaded before printing
            document.querySelector('img').onload = () => {
              window.print();
            };

            // Close the window after printing or canceling
            window.onafterprint = () => {
              window.close();
            };
          </script>
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to finish writing
  };

  return (
    <div>
      <AppContainer title="Accounts/Balance Sheet Statement">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              <div className="card-body">
                <Toaster />
                <form onSubmit={handleAdd}>
                  <div className="row">
                    <div className="col-md-3">
                      <b>Select Project:</b>
                      <Select
                        onChange={(e) => setProjectID(e.id)}
                        getOptionLabel={(option) => option.project_name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={projects}
                        value={
                          Array.isArray(projects) &&
                          projects.find((value) => value.id === projectID)
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <b>From:</b>
                      <input
                        type="date"
                        value={start_date}
                        onChange={(e) => setStart_date(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2">
                      <b>To:</b>
                      <input
                        type="date"
                        value={to_date}
                        onChange={(e) => setTo_date(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2 mt-4">
                      <button
                        style={{ borderRadius: "5px" }}
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary">
                        Get Report
                      </button>
                    </div>
                    <div className="col-md-2 mt-4">
                      <button
                        style={{ borderRadius: "5px" }}
                        onClick={(e) => handlePrint(e, "12345")} // Provide folio number dynamically if necessary
                        className="btn btn-success">
                        Print Data
                      </button>
                    </div>
                  </div>
                </form>
                <div className="" id="printablediv">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <h1 className="text-center mt-4">
                          {project_details && project_details}
                        </h1>
                        <h4 className="text-center">Balance Sheet Report</h4>
                        <h6
                          className="text-center"
                          style={{ textAlign: "center" }}>
                          <p>
                            {start_date && `from ${start_date} to ${to_date}`}
                          </p>
                        </h6>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}>
                      <div className="col-lg-5 col-md-5  ">
                        <h4 className="text-center">Assets</h4>
                        <>
                          <div className="row mt-4">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Legder Group</th>
                                  <th>Assets Debit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(Assets) &&
                                  Assets.map((ledger, i) => (
                                    // },
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>
                                        {/* <Link
                                    className="nav-link"
                                    target="_blank"
                                    to={`/accounts/getLedger_trial_report/${ledger.ledgerID_debited}/${project_details ||'NULL'}/${start_date}/${to_date}`}>
                                    <i class="fa-regular fa-eye" /> &nbsp;
                                    <span className="sidemenu-label"> */}
                                        {ledger.name}
                                        {/* </span> */}
                                        {/* </Link> */}
                                      </td>
                                      <td>
                                        Ugx&nbsp;
                                        {ledger.difference.difference_c}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                              {Assets === null && (
                                <>
                                  <tr>
                                    <td colSpan={10}>
                                      <p className="text-center text-info">
                                        No data found
                                      </p>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </table>
                          </div>
                          {/* Display Final Totals */}
                          <hr style={{ borderBottom: "2px dotted black" }} />
                          <div className="col-lg-12">
                            <table className="table table-striped table-bordered">
                              <tr>
                                <td>
                                  <b>Total Assets:</b>
                                </td>
                                <td></td>
                                <td>
                                  <b>
                                    Ugx&nbsp;
                                    {Assets_total && Assets_total.total_c}
                                  </b>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </>
                      </div>
                      <div className="col-lg-5 col-md-5 ">
                        <>
                          <h4 className="text-center">Liabilities</h4>
                          <div className="row mt-4">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Legder Group</th>
                                  <th>Liabilities credit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(Liabilities) &&
                                  Liabilities.map((ledger, i) => (
                                    // },
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>
                                        {/* <Link
                                    className="nav-link"
                                    target="_blank"
                                    to={`/accounts/getLedger_trial_report/${ledger.ledgerID_debited}/${project_details ||'NULL'}/${start_date}/${to_date}`}>
                                    <i class="fa-regular fa-eye" /> &nbsp;
                                    <span className="sidemenu-label"> */}
                                        {ledger.name}
                                        {/* </span> */}
                                        {/* </Link> */}
                                      </td>
                                      <td>
                                        Ugx&nbsp;
                                        {ledger.difference.difference_c}
                                      </td>
                                    </tr>
                                  ))}
                                {Liabilities === null && (
                                  <>
                                    <tr>
                                      <td colSpan={10}>
                                        <p className="text-center text-info">
                                          No data found
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {/* Display Final Totals */}
                          <hr style={{ borderBottom: "2px dotted black" }} />
                          <div className="col-lg-12">
                            <table className="table table-striped table-bordered">
                              <tr>
                                <td>
                                  <b>Total Liabilities:</b>
                                </td>
                                <td></td>
                                <td>
                                  <b>
                                    Ugx&nbsp;
                                    {Liabilities_total &&
                                      Liabilities_total.total_c}
                                  </b>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default BalanceSheetsStatememnt;
