import React, { useContext, useEffect, useState } from "react";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";
import AuthContext from "../../Context/AuthContext";
import ajaxUser from "../../util/remote/ajaxUser";
import ajaxStores from "../../util/remote/ajaxStores";
import ajaxProduct from "../../util/remote/ajaxProduct";

function JournalsInventoryCreate(props) {
  // Auth context
  const { userId } = useContext(AuthContext);

  // Loading & error handling state
  const [loading, setLoading] = useState(false);

  // Form data state
  const [delivered_by, setDelivered_by] = useState("");
  const [projectID, setProjectID] = useState("");
  const [LedgerID_credited, setLedgerID_credited] = useState("");
  const [LedgerID_debited, setLedgerID_debited] = useState("");
  const [description, setDescription] = useState("");
  const [deliveryDate, setdeliveryDate] = useState("");
  const [deliveryTime, setdeliveryTime] = useState("");
  const [receiver, setReceiver] = useState("");
  const [employee, setEmployee] = useState("");
  const [trans_type, setTrans_type] = useState("journal_inve");

  const [invoice_reff, setInvoice_reff] = useState("");
  const [Supplier_invoice_ref, setSupplier_invoice_ref] = useState("");
  const [voucher_no, setVoucher_no] = useState("");
  const [storeID, setStoreID] = useState("");
  const [NOPlate, setNOPlate] = useState("");
  const [vatApplied, setVatApplied] = useState(false);
  // Rows data state
  const [rows, setRows] = useState([
    { item: "", quantity: "", rate: "", unit: "", amount: "" },
  ]);

  // Dropdown data state
  const [employees, setEmployees] = useState([]);
  const [Debitledgers, setDebitledgers] = useState([]);
  const [creditLedger, setCreditLedger] = useState([]);
  const [stores, setStores] = useState([]);
  const [projects, setProjects] = useState([]);
  const [invoicables, setInvoicables] = useState([]);
  const [productList, setProductList] = useState(false);

  // Initialization of dropdown data
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setdeliveryDate(today);

    getDebitlegders();
    getCreditlegders();
    getEmployees();
    fetch_store();
    getProjects();
    getInvoicables();
    getProducts();
  }, []);

  const getEmployees = async () => {
    const response = await ajaxUser.getUsersMinimal_data();
    setEmployees(response.status === "OK" ? response.details : []);
  };

  const getDebitlegders = async () => {
    const response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    setDebitledgers(response.status === "OK" ? response.details : []);
  };

  const getCreditlegders = async () => {
    const response = await ajaxAccounting.getALlLedgersMinData_cash_or_bank();
    setCreditLedger(response.status === "OK" ? response.details : []);
  };

  const fetch_store = async () => {
    const response = await ajaxStores.fetchStores();
    setStores(response.status === "OK" ? response.details : []);
  };

  const getProjects = async () => {
    const response = await ajaxProject.getProjectsMinnimalDataCliented();
    setProjects(response.status === "OK" ? response.details : []);
  };

  const getInvoicables = async () => {
    const response = await ajaxAccounting.getAllSalesInvoices();
    setInvoicables(response.status === "OK" ? response.details : []);
    if (response.status !== "OK") toast.error(response.message);
  };

  const getProducts = async () => {
    const server_response = await ajaxProduct.fetchProductListminiData();

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };
  const formatMoney = (value) => {
    return value
      ? value.toLocaleString("en-UG", {
          style: "currency",
          currency: "UGX",
          minimumFractionDigits: 2,
        })
      : "UGX 0.00";
  };

  // Row-level handlers
  const handleAddRow = () => {
    const lastRow = rows[rows.length - 1];
    if (!lastRow.item || !lastRow.quantity || !lastRow.rate) {
      toast.error("Please complete the current row before adding a new one.");
      return;
    }
    setRows([
      ...rows,
      { item: "", quantity: "", rate: "", unit: "", amount: "" },
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, idx) => idx !== index);
    setRows(
      updatedRows.length === 0
        ? [{ item: "", quantity: "", rate: "", unit: "", amount: "" }]
        : updatedRows
    );
  };

  const handleItemChange = (index, selectedInvoicable) => {
    const updatedRows = [...rows];
    if (
      updatedRows.some(
        (row, idx) => idx !== index && row.item === selectedInvoicable
      )
    ) {
      toast.error("This item has already been entered in a previous row.");
      return;
    }

    const selectedItem = productList.find(
      (invoicable) => invoicable.id === selectedInvoicable
    );
    updatedRows[index] = {
      ...updatedRows[index],
      item: selectedItem.id,
      unit: selectedItem.unit,
      amount: updatedRows[index].quantity * selectedItem.rate,
    };
    setRows(updatedRows);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index].quantity = quantity;
    updatedRows[index].amount = quantity * updatedRows[index].rate;
    setRows(updatedRows);
  };

  const handleRateChange = (index, rate) => {
    const updatedRows = [...rows];
    updatedRows[index].rate = rate;
    updatedRows[index].amount = updatedRows[index].quantity * rate;
    setRows(updatedRows);
  };

  const allRowsFilled = () => {
    return rows.every((row) => row.item && row.quantity > 0 && row.rate > 0);
  };
  // Form submission and validation
  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      projectID.trim() === "" ||
      LedgerID_credited.trim() === "" ||
      LedgerID_debited.trim() === ""
    ) {
      toast.error(
        "Please complete all fields and ensure selected ledgers are not the same."
      );
      return;
    } else if (LedgerID_credited === LedgerID_debited) {
      toast.error(
        " You have choosen the same ledger on both credit and debit."
      );
    }
    const outputData = rows.map(({ item, quantity, rate, amount }) => ({
      productID: item,
      quantity: quantity,
      rate: rate,
      amount: amount,
    }));

    setLoading(true);
    const data = {
      folioID: "",
      delivered_by: delivered_by,
      projectID: projectID,
      storeID: storeID,
      LedgerID_debited: LedgerID_debited,
      LedgerID_credited: LedgerID_credited,
      deliveryDate: deliveryDate,
      deliveryTime: deliveryTime,
      received_by: receiver,
      employee: employee,
      NOPlate: NOPlate,
      created_by: userId,
      trans_type: trans_type,
      description: description,
      Supplier_invoice_ref: Supplier_invoice_ref,
      voucher_no: voucher_no,
      vat_applied: vatApplied,
      details: outputData,
    };

    const response = await ajaxAccounting.createJournalInventory(data);
    setLoading(false);
    if (response.status === "OK") {
      toast.success(response.message);
      props.funct();
      resetForm();
    } else {
      toast.error(response.message);
    }
  };

  const resetForm = () => {
    setProjectID("");
    setDelivered_by("");
    setdeliveryDate("");
    setdeliveryTime("");
    setLedgerID_credited("");
    setLedgerID_debited("");
    setDescription("");
    setRows([{ item: "", quantity: "", rate: "", unit: "", amount: "" }]);
    setVatApplied(false);
  };

  const calculateTotalAmount = () => {
    return rows.reduce(
      (total, row) => total + (parseFloat(row.amount) || 0),
      0
    );
  };

  const calculateVAT = () => {
    return vatApplied ? calculateTotalAmount() * 0.18 : 0;
  };

  const calculateGrandTotal = () => {
    return calculateTotalAmount() + calculateVAT();
  };

  return (
    <>
      <Toaster />
      <div className="col-lg-12 col-md-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="card custom-card" style={{ borderRadius: "10px" }}>
              <div className="card-body">
                <h6 className="card-title mb-4">Add Inventory Journals </h6>
                <form onSubmit={handleAdd} method="post">
                  <div className="row">
                    <input
                      type="hidden"
                      value={trans_type}
                      onChange={(e) => setTrans_type(e.target.value)}
                      className="form-control"
                    />
                    <div className="mb-4 col-md-4">
                      <label>
                        select project <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        onChange={(e) => setProjectID(e.id)}
                        getOptionLabel={(option) => option.project_name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={projects}
                        value={projects.find((p) => p.id === projectID) || ""}
                      />
                    </div>
                    <div className="mb-4 col-md-4">
                      <label>
                        Select Account To Debit{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        onChange={(e) => setLedgerID_debited(e.id)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={Debitledgers}
                        value={
                          Debitledgers.find((d) => d.id === LedgerID_debited) ||
                          ""
                        }
                      />
                    </div>
                    <div className="mb-4 col-md-4">
                      <label>
                        Select Account To Credit{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        onChange={(e) => setLedgerID_credited(e.id)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={Debitledgers}
                        value={
                          Debitledgers.find(
                            (c) => c.id === LedgerID_credited
                          ) || ""
                        }
                      />
                    </div>
                    <div className="mb-4 col-md-4">
                      <label>
                        select Store <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        onChange={(e) => setStoreID(e.id)}
                        getOptionLabel={(option) => option.storeName}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={stores}
                        value={stores.find((s) => s.id === storeID) || ""}
                      />
                    </div>
                    <div className="mb-4 col-md-4">
                      <label>
                        Delivered By <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={delivered_by}
                        onChange={(e) => setDelivered_by(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-4 col-md-4">
                      <label>
                        Supplier Invoice Ref{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={Supplier_invoice_ref}
                        onChange={(e) =>
                          setSupplier_invoice_ref(e.target.value)
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="mb-4 col-md-6">
                      <label>
                        Received by <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        onChange={(e) => setReceiver(e.id)}
                        getOptionLabel={(option) => option.User_name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={Array.isArray(employees) ? employees : []}
                        value={
                          receiver
                            ? Array.isArray(employees) &&
                              employees.find((value) => value.id === receiver)
                            : ""
                        }
                      />
                    </div>
                    <div className="mb-4 col-md-3">
                      <label>
                        Delivery Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        value={deliveryDate}
                        onChange={(e) => setdeliveryDate(e.target.value)}
                        className="form-control"
                      />
                    </div>

                    <div className="mb-4 col-md-3">
                      <label>
                        Delivery Time <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="time"
                        value={deliveryTime}
                        onChange={(e) => setdeliveryTime(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-4 col-md-3">
                      <label>
                        Delivery Vehicle No. Plate{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={NOPlate}
                        onChange={(e) => setNOPlate(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-4 col-md-9">
                      <label>Description</label>
                      <textarea
                        rows="2"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"></textarea>
                    </div>
                  </div>
                  {/* V.A.T handler  */}
                  <div className="mb-4 col-md-12">
                    <label>
                      <input
                        type="checkbox"
                        checked={vatApplied}
                        onChange={(e) => setVatApplied(e.target.checked)}
                        className="mr-2"
                      />
                      Apply VAT (18%)
                    </label>
                  </div>

                  <div className="mb-4 col-md-12">
                    <hr className="mg-b-40" />
                    <table className="table table-hover text-nowrap mg-b-0">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th className="wd-25p">Product</th>
                          <th className="wd-15p">Unit</th>
                          <th className="wd-15p">Rate</th>
                          <th className="wd-15p">Quantity</th>
                          <th className="wd-15p">Amount</th>
                          <th>Operations</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <Select
                                onChange={(e) => handleItemChange(index, e.id)}
                                getOptionLabel={(option) => option.product_name}
                                getOptionValue={(option) => option.id}
                                isSearchable
                                options={
                                  Array.isArray(productList) ? productList : ""
                                }
                                value={
                                  row.item
                                    ? Array.isArray(productList) &&
                                      productList.find(
                                        (value) => value.id === row.item
                                      )
                                    : ""
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control mb-4 col-md-12"
                                type="text"
                                value={row.unit}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                className="form-control mb-4 col-md-12"
                                type="number"
                                value={row.rate}
                                onChange={(e) =>
                                  handleRateChange(index, e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control mb-4 col-md-12"
                                type="number"
                                min={1}
                                value={row.quantity}
                                onChange={(e) =>
                                  handleQuantityChange(index, e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control mb-4 col-md-12"
                                type="number"
                                value={row.amount}
                                readOnly
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => handleDeleteRow(index)}
                                type="button">
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan="4">
                            <button
                              type="button"
                              onClick={handleAddRow}
                              className="btn btn-secondary btn-sm mr-2"
                              disabled={loading}>
                              Add Row
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* Totals */}
                  <div className="mb-4 col-md-12">
                    <div className="row">
                      <div
                        className="col-lg-3"
                        style={{
                          border: "1px groove black",
                          margin: "10px",
                          padding: "10px",
                        }}>
                        <p>
                          Total (No VAT): {formatMoney(calculateTotalAmount())}
                        </p>
                      </div>{" "}
                      {vatApplied && (
                        <>
                          <div
                            className="col-lg-3"
                            style={{
                              border: "1px groove black",
                              margin: "10px",
                              padding: "10px",
                            }}>
                            <p>VAT (18%): {formatMoney(calculateVAT())}</p>
                          </div>
                          <div
                            className="col-lg-3"
                            style={{
                              border: "1px groove black",
                              margin: "10px",
                              padding: "10px",
                            }}>
                            <p>
                              Grand Total: {formatMoney(calculateGrandTotal())}
                            </p>
                          </div>
                        </>
                      )}
                    </div>{" "}
                  </div>
                  <div className="d-flex justify-content-center col-lg-12">
                    <button
                      type="submit"
                      className="btn btn-success col-lg-12"
                      disabled={!allRowsFilled() || loading}>
                      {loading ? <Loader /> : "Add Inventory"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JournalsInventoryCreate;
