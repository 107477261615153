import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";
import AuthContext from "../../Context/AuthContext";

import ajaxUser from "../../util/remote/ajaxUser";

function PaymentVoucherCreate(props) {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [projectID, setProjectID] = useState("");
  const [LedgerID_credited, setLedgerID_credited] = useState("");
  const [LedgerID_debited, setLedgerID_debited] = useState("");
  const [description, setDescription] = useState("");
  const [cost_item, setCost_item] = useState("");
  const [date, setDate] = useState("");
  const [reciever, setReciever] = useState("");
  const [employee, setEmployee] = useState("");
  const [trans_type, setTrans_type] = useState("payment_voucher");
  const [invoice_reff, setInvoice_reff] = useState("");
  const [Supplier_ivoice_reff, setSupplier_ivoice_reff] = useState("");
  const [voucher_no, setVoucher_no] = useState("");

  const { user, userId } = useContext(AuthContext);
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      projectID.trim() === "" ||
      LedgerID_credited.trim() === "" ||
      LedgerID_debited.trim() === "" ||
      amount.trim() === "" ||
      date.trim() === "" ||
      LedgerID_credited === LedgerID_debited // checks  if the ledgers selected are not the same
    ) {
      toast.error(
        "Please complete all fields and make sure the selected ledger are (s) are not the same"
      );
    } else {
      setLoading(true);
      var data = {
        folioID: "",
        amount: amount,
        projectID: projectID,
        LedgerID_debited: LedgerID_debited,
        LedgerID_credited: LedgerID_credited,
        cost_item: cost_item,
        date: date,
        reciever: reciever,
        employee: employee,
        created_by: userId,
        trans_type: trans_type,
        description: description,
        Supplier_ivoice_reff: Supplier_ivoice_reff,
        voucher_no: voucher_no,
        invoice_reff: invoice_reff,
      };

      const server_response = await ajaxAccounting.CreatePaymentVoucher(data);
      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.function();
        resetForm();
      } else {
        toast.error(server_response.message);
      }
    }
  };
  const resetForm = () => {
    setProjectID("");
    setAmount("");
    setDate("");
    setCost_item("");
    setLedgerID_credited("");
    setLedgerID_debited("");
    setDescription("");
    setVoucher_no("");
    setReciever("");
  };

  const [Debitledgers, setDebitledgers] = useState("");
  const getDebitlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    if (server_response.status === "OK") {
      setDebitledgers(server_response.details);
    } else {
      setDebitledgers("404");
    }
  };
  const [creditLedger, setCreditLedger] = useState("");
  const getCreditlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_cash_or_bank();
    if (server_response.status === "OK") {
      setCreditLedger(server_response.details);
    } else {
      setCreditLedger("404");
    }
  };

  // ledgers fetching +++++++++++++++
  useEffect(() => {
    getDebitlegders();
    getCreditlegders();
  }, []);

  const [employees, setEmployees] = useState("");
  const getEmployees = async () => {
    const server_response = await ajaxUser.getUsersMinimal_data();
    if (server_response.status === "OK") {
      setEmployees(server_response.details);
    } else {
      setEmployees("404");
    }
  };

  const [projects, setProjects] = useState("");
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects("404");
    }
  };

  const [cost_allocations, setCost_allocations] = useState("");
  const getCost_Allocations = async () => {
    const server_response =
      await ajaxAccounting.getAllcostAllocations_min_data();
    if (server_response.status === "OK") {
      setCost_allocations(server_response.details);
    } else {
      setCost_allocations("404");
    }
  };
  useEffect(() => {
    getProjects();
    getCost_Allocations();
    getEmployees();
  }, []);
  // end posting works

  return (
    <div>
      <Toaster />
      <div className="col-lg-12 col-md-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body">
            <div>
              <h6 className="card-title mb-4">Add Payment Voucher</h6>
            </div>

            <form onSubmit={(e) => handleAdd(e)} method="post">
              <div className="row">
                <input
                  type="hidden"
                  value={trans_type}
                  onChange={(e) => setTrans_type(e.target.value)}
                  className="form-control"
                />
                <div className="mb-4 col-md-3">
                  <label htmlFor="">
                    select project
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    onChange={(e) => setProjectID(e.id)}
                    getOptionLabel={(option) => option.project_name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    options={projects}
                    value={
                      projectID
                        ? Array.isArray(projects) &&
                          projects.find((value) => value.id === projectID)
                        : ""
                    }
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <label htmlFor="">
                    Select Account To Debit
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    onChange={(e) => setLedgerID_debited(e.id)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    options={Array.isArray(Debitledgers) ? Debitledgers : []}
                    value={
                      LedgerID_debited
                        ? Array.isArray(Debitledgers) &&
                          Debitledgers.find(
                            (value) => value.id === LedgerID_debited
                          )
                        : ""
                    }
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <label htmlFor="">
                    Select Account To Credit
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    onChange={(e) => setLedgerID_credited(e.id)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    options={Array.isArray(creditLedger) ? creditLedger : []}
                    value={
                      LedgerID_credited
                        ? Array.isArray(creditLedger) &&
                          creditLedger.find(
                            (value) => value.id === LedgerID_credited
                          )
                        : ""
                    }
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <label htmlFor="">cost allocation center</label>
                  <Select
                    onChange={(e) => setCost_item(e.id)}
                    getOptionLabel={(option) => option.item}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    options={
                      Array.isArray(cost_allocations) ? cost_allocations : [""]
                    }
                    value={
                      cost_item
                        ? Array.isArray(cost_allocations) &&
                          cost_allocations.find(
                            (value) => value.id === cost_item
                          )
                        : ""
                    }
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <label htmlFor="">
                    Amount
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <label htmlFor="">
                    Legger_reference{" "}
                    <span style={{ color: "red" }}>for old Ledgers</span>
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={voucher_no}
                    onChange={(e) => setVoucher_no(e.target.value)}
                    className="form-control"
                    placeholder="ledger reference"
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <label htmlFor="">
                    Reciever
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={reciever}
                    onChange={(e) => setReciever(e.target.value)}
                    className="form-control"
                  />
                </div>

                <div className="mb-4 col-md-4">
                  <label htmlFor="">Employee</label>
                  <Select
                    onChange={(e) => setEmployee(e.id)}
                    getOptionLabel={(option) => option.User_name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    options={Array.isArray(employees) ? employees : [""]}
                    value={
                      employee
                        ? Array.isArray(employees) &&
                          employees.find((value) => value.id === employee)
                        : ""
                    }
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <label htmlFor="">
                    Date
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-4 col-md-12">
                  <label htmlFor="">
                    Description
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control"></textarea>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <input
                  disabled={loading}
                  type="submit"
                  style={{ borderRadius: "10px" }}
                  className="btn btn-success col-lg-12"
                  value="Save Payment Voucher"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentVoucherCreate;
