import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";
import useStateCallback from "../../util/customHooks/useStateCallback";
import ajaxUser from "../../util/remote/ajaxUser";
import InvoiceInventoryCreate from "./InvoiceInventoryCreate";
import InventoryInvoicePrintable from "./InventoryInvoicePrintable ";
import InvoicePrintable from "./InvoicePrintable";

function InvoiceInventory(props) {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("create_Budget_expense");
  const [trans_type, setTrans_type] = useState("Invoice_inve");
  const [employees, setEmployees] = useState("");
  const getEmployees = async () => {
    const server_response = await ajaxUser.getUsersMinimal_data();
    if (server_response.status === "OK") {
      setEmployees(server_response.details);
    } else {
      setEmployees("404");
    }
  };

  const [Debitledgers, setDebitledgers] = useState("");
  const getDebitlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    if (server_response.status === "OK") {
      setDebitledgers(server_response.details);
    } else {
      setDebitledgers("404");
    }
  };

  // ledgers fetching +++++++++++++++
  useEffect(() => {
    getDebitlegders();
    getEmployees();
  }, []);

  const [projects, setProjects] = useState("");
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects("404");
    }
  };

  const [projectss, setProjectss] = useState("");
  const getProjectss = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      const allOption = { id: "", project_name: "All Projects" };
      setProjectss([allOption, ...server_response.details]);
    } else {
      setProjectss([{ id: "", project_name: "All Projects" }]);
    }
  };
  useEffect(() => {
    getProjectss();
  }, []);
  const [cost_allocations, setCost_allocations] = useState("");
  const getCost_Allocations = async () => {
    const server_response =
      await ajaxAccounting.getAllcostAllocations_min_data();
    if (server_response.status === "OK") {
      setCost_allocations(server_response.details);
    } else {
      setCost_allocations("404");
    }
  };
  useEffect(() => {
    getProjects();
    getCost_Allocations();
  }, []);
  // end posting works

  // fetch data of PaymentVoucher

  const [projectIDD, setProjectIDD] = useState("");

  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const [PaymentVoucher, setPAymentVoucher] = useState("");
  const gettransactions = async () => {
    var data = {
      projectID: projectIDD,
      trans_type: trans_type,
      page: page,
    };
    setLoading(true);
    setMeta("");
    setPAymentVoucher("");

    const server_response = await ajaxAccounting.getPaymentVoucher(data);
    setLoading(false);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setPAymentVoucher(server_response.details.list);
    } else {
      setPAymentVoucher("404");
    }
  };

  useEffect(() => {
    gettransactions();
  }, [projectIDD, page]);

  // pagination handlers
  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };

  const [printing1, setPrinting1] = useStateCallback(false);

  const handleInoviceView1 = (e, folioID) => {
    e.preventDefault();
    setPrinting1(false, () =>
      setPrinting1(<InvoicePrintable isOpen={true} id={folioID} />)
    );
  };
  const [printing2, setPrinting2] = useStateCallback(false);

  const handleInoviceView2 = (e, folioID) => {
    e.preventDefault();
    setPrinting2(false, () =>
      setPrinting2(
        <InventoryInvoicePrintable
          isOpen={true}
          id={folioID}
          type={trans_type}
        />
      )
    );
  };

  return (
    <div>
      <AppContainer title="Accounting/Invoice/Inventory">
        <div className="row">
          {printing2}
          {printing1}
          <Toaster />
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              {/* Tabs navigation */}
              <nav className="nav main-nav-line">
                <a
                  className={`nav-link ${
                    activeTab === "create_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("create_Budget_expense")}
                  style={{ cursor: "pointer" }}>
                  Add Invoice
                </a>
                <a
                  className={`nav-link ${
                    activeTab === "View_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("View_Budget_expense")}
                  style={{ cursor: "pointer" }}>
                  View Invoice
                </a>
              </nav>

              {/* Tab Content */}
              <div className="card-body tab-content h-100">
                {/* create_Budget_expense Tab */}
                {activeTab === "create_Budget_expense" && (
                  <div className="row">
                    <InvoiceInventoryCreate funct={gettransactions} />
                  </div>
                )}
                {/* View_Budget_expense Tab */}
                {activeTab === "View_Budget_expense" && (
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "-5px", marginLeft: "-5px" }}>
                      <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                        <div
                          className="card mb-1"
                          style={{ borderRadius: ".55rem" }}>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 col-xl-12 mb-0">
                                <div className="row">
                                  <div className="form-group col-md-3 col-lg-3 mt-1">
                                    <b>Select Project:</b>
                                    <br />
                                    <Select
                                      onChange={(e) => setProjectIDD(e.id)}
                                      getOptionLabel={(option) =>
                                        option.project_name
                                      }
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={projectss}
                                      value={
                                        Array.isArray(projectss) &&
                                        projectss.find(
                                          (value) => value.id === projectIDD
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-lg-3 col-md-3 mt-4">
                                    <div className="input-group-append">
                                      <button
                                        // onClick={() => setSearch_p("")}
                                        style={{ borderRadius: "5px" }}
                                        className="btn ripple btn-primary btn-with-icon">
                                        <i class="fa-solid fa-arrow-rotate-left"></i>
                                        Reset filters
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      {/* row */}
                      <div className="card custom-card">
                        <div className="card-body">
                          <div>
                            <h6 className="card-title mb-4">Journals View</h6>
                          </div>
                          <div className="table-responsive">
                            {loading && <Loader />}
                            <table
                              className="table table-striped table-bordered"
                              style={{ border: "orange 3px groove" }}>
                              <thead>
                                <th>#</th>
                                <th>Invoice no.</th>
                                <th>Project</th>
                                <th>ledger Debited</th>
                                <th>ledger Credited</th>
                                <th>Amount</th>
                                <th>details</th>
                                <th>Date</th>
                                <th>Reciever</th>
                                <th>Created By</th>
                              </thead>
                              <tbody>
                                {Array.isArray(PaymentVoucher) &&
                                  PaymentVoucher.map((item, key) => (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>
                                        {item.folio_reff}
                                        <br />
                                        {item.invento_stat == 0 ? (
                                          <>
                                            <button
                                              onClick={(e) =>
                                                handleInoviceView1(e, item.id)
                                              }
                                              style={{
                                                borderRadius: "5px",
                                                margin: "2px",
                                              }}
                                              className="btn ripple btn-warning btn-sm">
                                              <i class="fa-regular fa-eye"></i>
                                              &nbsp; view
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              onClick={(e) =>
                                                handleInoviceView2(e, item.id)
                                              }
                                              style={{
                                                borderRadius: "5px",
                                                margin: "2px",
                                              }}
                                              className="btn ripple btn-warning btn-sm">
                                              <i class="fa-regular fa-eye"></i>
                                              &nbsp; view Invoice
                                            </button>
                                          </>
                                        )}
                                      </td>
                                      <td>{item.projectID.project_name}</td>
                                      <td>
                                        {item.ledgerID_debited.Ledger_name}
                                      </td>
                                      <td>
                                        {item.ledgerID_credited.Ledger_name}
                                      </td>
                                      <td>{item.amount.amount_p}</td>
                                      <td
                                        style={{
                                          width: "200px",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                        }}>
                                        {item.description}
                                      </td>
                                      <td>{item.date.short_date}</td>{" "}
                                      <td>{item.reciever}</td>
                                      <td>{item.created_by.name}</td>
                                    </tr>
                                  ))}

                                {loading && <Loader />}
                                {PaymentVoucher === "404" && (
                                  <>
                                    <tr>
                                      <td colSpan={10}>
                                        <p className="text-center text-info">
                                          No data found in the system
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            <nav
                              className="mt-3"
                              style={{ marginLeft: "30px" }}>
                              <ul className="pagination ">
                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setPreviousPageNumber}>
                                    Prev
                                  </button>
                                </li>

                                {Array.isArray(meta) &&
                                  meta.map((item) =>
                                    page === item ? (
                                      <li className="page-item active">
                                        <a
                                          className="page-link"
                                          href="javascript:void(0);">
                                          {item}
                                        </a>
                                      </li>
                                    ) : (
                                      <li className="page-item">
                                        <a
                                          className="page-link"
                                          href="#"
                                          onClick={(e) =>
                                            setPageNumber(e, item)
                                          }>
                                          {item}
                                        </a>
                                      </li>
                                    )
                                  )}

                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setNextPageNumber}>
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* end row  */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default InvoiceInventory;
