import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";
import AuthContext from "../../Context/AuthContext";
import useStateCallback from "../../util/customHooks/useStateCallback";
import BudgetExpenditureCatUpdater from "./BudgetExpenditureCatUpdater";
import BudgetExpenditureUpdater from "./BudgetExpenditureUpdater";

function BudgetExpenses(props) {
  // Define state for the active tab
  const [activeTab, setActiveTab] = useState("create_Budget_expense");
  const [amount, setAmount] = useState("");
  const [projectID, setProjectID] = useState("");
  const [LedgerID, setLedgerID] = useState("");
  const [description, setDescription] = useState("");
  const [cost_item, setCost_item] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  const { user, userId } = useContext(AuthContext);
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);
  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      projectID.trim() === "" ||
      LedgerID.trim() === "" ||
      amount.trim() === "" ||
      date.trim() === ""
    ) {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);
      var data = {
        amount: amount,
        projectID: projectID,
        LedgerID: LedgerID,
        cost_item: cost_item,
        date: date,
        description: description,
        created_by: userId,
      };
      const server_response = await ajaxAccounting.createBugdetExpense(data);
      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);

        getBudget_expenses();
        resetForm();
      } else {
        toast.error(server_response.message);
      }
    }
  };
  const resetForm = () => {
    setProjectID("");
    setAmount("");
    setDate("");
    setCost_item("");
    setLedgerID("");
    setDescription("");
  };

  const [Debitledgers, setDebitledgers] = useState("");
  const getDebitlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    if (server_response.status === "OK") {
      setDebitledgers(server_response.details);
    } else {
      setDebitledgers("404");
    }
  };

  // ledgers fetching +++++++++++++++
  useEffect(() => {
    getDebitlegders();
  }, []);

  const [projects, setProjects] = useState("");
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects("404");
    }
  };

  const [cost_allocations, setCost_allocations] = useState("");
  const getCost_Allocations = async () => {
    const server_response =
      await ajaxAccounting.getAllcostAllocations_min_data();
    if (server_response.status === "OK") {
      setCost_allocations(server_response.details);
    } else {
      setCost_allocations("404");
    }
  };
  useEffect(() => {
    getProjects();
    getCost_Allocations();
  }, []);
  // end posting works
  const [projectIDD, setProjectIDD] = useState("");
  const [LedgerIDD, setLedgerIDD] = useState("");
  const [cost_itemm, setCost_itemm] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const [budget_expenses, setBudget_expenses] = useState("");
  const getBudget_expenses = async () => {
    var data = {
      projectID: projectIDD,
      LedgerID: LedgerIDD,
      cost_item: cost_itemm,
      page: page,
    };
    setMeta("");
    setBudget_expenses("");
    const server_response = await ajaxAccounting.getBudgetExpenses(data);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setBudget_expenses(server_response.details.list);
    } else {
      setBudget_expenses("404");
    }
  };
  const [projectss, setProjectss] = useState("");
  const getProjectss = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      const allOption = { id: "", project_name: "All Projects" };
      setProjectss([allOption, ...server_response.details]);
    } else {
      setProjectss([{ id: "", project_name: "All Projects" }]);
    }
  };
  useEffect(() => {
    getProjectss();
  }, []);

  useEffect(() => {
    getBudget_expenses();
  }, [projectIDD, LedgerIDD, cost_itemm, page]);

  const resetFilters = (e) => {
    setLedgerIDD("");
    setProjectIDD("");
    setCost_itemm("");
  };
  // pagination handlers
  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };

  // cost allocation updating
  const [modal, setModal] = useStateCallback(false);

  const handleModal = (e, id, item) => {
    e.preventDefault();
    setModal(false, () =>
      setModal(
        <BudgetExpenditureCatUpdater
          isOpen={true}
          function={getBudget_expenses}
          cost_allocations={cost_allocations}
          expense={id}
          item={item}
        />
      )
    );
  };
  const [budgetUpdata, setBudgetUpdate] = useStateCallback(false);

  const HandleBudgetExpenseUpdating = (e, data) => {
    e.preventDefault();
    setBudgetUpdate(false, () =>
      setBudgetUpdate(
        <BudgetExpenditureUpdater
          isOpen={true}
          projects={projects}
          ledgers={Debitledgers}
          data={data}
          function={getBudget_expenses}
        />
      )
    );
  };

  return (
    <div>
      <AppContainer title="Accounting/Budget Expenses">
        <div className="row">
          {modal}
          {budgetUpdata}
          <Toaster />
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              {/* Tabs navigation */}
              <nav className="nav main-nav-line">
                <a
                  className={`nav-link ${
                    activeTab === "create_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("create_Budget_expense")}
                  style={{ cursor: "pointer" }}>
                  Create Budget Expense
                </a>
                <a
                  className={`nav-link ${
                    activeTab === "View_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("View_Budget_expense")}
                  style={{ cursor: "pointer" }}>
                  View Budget Expense
                </a>
              </nav>

              {/* Tab Content */}
              <div className="card-body tab-content h-100">
                {/* create_Budget_expense Tab */}
                {activeTab === "create_Budget_expense" && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div
                            className="card custom-card"
                            style={{ borderRadius: "10px" }}>
                            <div className="card-body">
                              <div>
                                <h6 className="card-title mb-4">
                                  Add Budget Expense
                                </h6>
                              </div>

                              <form
                                onSubmit={(e) => handleAdd(e)}
                                method="post">
                                <div className="row">
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      select project
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Select
                                      onChange={(e) => setProjectID(e.id)}
                                      getOptionLabel={(option) =>
                                        option.project_name
                                      }
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={projects}
                                      value={
                                        projectID
                                          ? Array.isArray(projects) &&
                                            projects.find(
                                              (value) => value.id === projectID
                                            )
                                          : projectID
                                      }
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      select Ledger
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Select
                                      onChange={(e) => setLedgerID(e.id)}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(Debitledgers) &&
                                        Debitledgers
                                      }
                                      value={
                                        LedgerID
                                          ? Array.isArray(Debitledgers) &&
                                            Debitledgers.find(
                                              (value) => value.id === LedgerID
                                            )
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      cost allocation center
                                    </label>
                                    <Select
                                      onChange={(e) => setCost_item(e.id)}
                                      getOptionLabel={(option) => option.item}
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(cost_allocations) &&
                                        cost_allocations
                                      }
                                      value={
                                        cost_item
                                          ? Array.isArray(cost_allocations) &&
                                            cost_allocations.find(
                                              (value) => value.id === cost_item
                                            )
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      Amount
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      value={amount}
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      Description
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      Date
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="date"
                                      value={date}
                                      onChange={(e) => setDate(e.target.value)}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                  <input
                                    type="submit"
                                    disabled={loading}
                                    style={{ borderRadius: "5px" }}
                                    className="btn btn-success col-lg-12"
                                    value="Save Budget expense"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* View_Budget_expense Tab */}
                {activeTab === "View_Budget_expense" && (
                  <div className="row">
                    <Toaster />

                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "-5px", marginLeft: "-5px" }}>
                      <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                        <div
                          className="card mb-1"
                          style={{ borderRadius: ".55rem" }}>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 col-xl-12 mb-0">
                                <div className="row">
                                  <div className="form-group col-md-3 col-lg-3 mt-1">
                                    <b>Select Project:</b>
                                    <br />
                                    <Select
                                      onChange={(e) => setProjectIDD(e.id)}
                                      getOptionLabel={(option) =>
                                        option.project_name
                                      }
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={projectss}
                                      value={
                                        projectIDD
                                          ? Array.isArray(projectss) &&
                                            projectss.find(
                                              (value) => value.id === projectIDD
                                            )
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-md-3 col-lg-3 mt-1">
                                    <b> Select Ledger:</b>
                                    <br />
                                    <Select
                                      onChange={(e) => setLedgerIDD(e.id)}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(Debitledgers) &&
                                        Debitledgers
                                      }
                                      value={
                                        LedgerIDD
                                          ? Array.isArray(Debitledgers) &&
                                            Debitledgers.find(
                                              (value) => value.id === LedgerIDD
                                            )
                                          : ""
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-md-3 col-lg-3 mt-1">
                                    <b> cost allocation center</b>
                                    <br />
                                    <Select
                                      onChange={(e) => setCost_itemm(e.id)}
                                      getOptionLabel={(option) => option.item}
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(cost_allocations) &&
                                        cost_allocations
                                      }
                                      value={
                                        cost_itemm
                                          ? Array.isArray(cost_allocations) &&
                                            cost_allocations.find(
                                              (value) => value.id === cost_itemm
                                            )
                                          : ""
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-lg-3 col-md-3 mt-4">
                                    <div className="input-group-append">
                                      <button
                                        onClick={(e) => resetFilters(e)}
                                        style={{ borderRadius: "20px" }}
                                        className="btn ripple btn-primary btn-with-icon">
                                        <i class="fa-solid fa-arrow-rotate-left"></i>
                                        Reset filters
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      {/* row */}
                      <div className="card custom-card">
                        <div className="card-body">
                          <div>
                            <h6 className="card-title mb-4">
                              {" "}
                              Budget Expenses{" "}
                            </h6>
                          </div>
                          <div className="table-responsive">
                            <table
                              className="table table-striped table-bordered"
                              style={{ border: "orange 3px groove" }}>
                              <thead>
                                <th>#</th>
                                <th>Project</th>
                                <th>
                                  Ledger <br /> Name
                                </th>
                                <th>Amount</th>
                                <th>details</th>
                                <th>
                                  cost Allocation <br />
                                  item
                                </th>
                                <th>
                                  Transaction <br />
                                  date
                                </th>
                                <th>
                                  Main Acconut <br /> Group
                                </th>
                                <th>Ledger Group</th>
                              </thead>
                              <tbody>
                                {Array.isArray(budget_expenses) &&
                                  budget_expenses.map((item, key) => (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>
                                        {item.project_name}
                                        <br />
                                        <button
                                          onClick={(e) =>
                                            HandleBudgetExpenseUpdating(e, item)
                                          }
                                          style={{
                                            borderRadius: "5px",
                                            margin: "2px",
                                          }}
                                          className="btn ripple btn-warning btn-sm">
                                          <i class="fa-solid fa-pen-to-square"></i>
                                          &nbsp; Update Expense
                                        </button>
                                      </td>
                                      <td>{item.ledger_name}</td>
                                      <td>{item.amount.amount_p}</td>
                                      <td>{item.description}</td>
                                      <td>
                                        {item.cat_balance &&
                                        item.cat_balance.cat_balance > 0 ? (
                                          <>
                                            <button
                                              onClick={(e) =>
                                                handleModal(e, item.id, item)
                                              }
                                              style={{ borderRadius: "5px" }}
                                              className="btn ripple btn-primary btn-sm">
                                              <i class="fa-solid fa-plus"></i>
                                              Add CAC
                                            </button>
                                            <p>
                                              total_allocated : Ugx{" "}
                                              {item.amount_used.amount_used_c}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <p>
                                              total_allocated : Ugx{" "}
                                              {item.amount_used.amount_used_c}
                                            </p>
                                          </>
                                        )}
                                      </td>
                                      <td>{item.transaction_date}</td>
                                      <td>{item.main_account}</td>
                                      <td>{item.ledger_group}</td>
                                    </tr>
                                  ))}

                                {loading && <Loader />}
                                {budget_expenses === "404" && (
                                  <>
                                    <tr>
                                      <td colSpan={9}>
                                        <p className="text-info text-center">
                                          No data found in the system
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            <nav
                              className="mt-3"
                              style={{ marginLeft: "30px" }}>
                              <ul className="pagination ">
                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setPreviousPageNumber}>
                                    Prev
                                  </button>
                                </li>

                                {Array.isArray(meta) &&
                                  meta.map((item) =>
                                    page === item ? (
                                      <li className="page-item active">
                                        <a
                                          className="page-link"
                                          href="javascript:void(0);">
                                          {item}
                                        </a>
                                      </li>
                                    ) : (
                                      <li className="page-item">
                                        <a
                                          className="page-link"
                                          href="#"
                                          onClick={(e) =>
                                            setPageNumber(e, item)
                                          }>
                                          {item}
                                        </a>
                                      </li>
                                    )
                                  )}

                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setNextPageNumber}>
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* end row  */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default BudgetExpenses;
